import React, {useState} from "react";
import Stars from "./stars";
const ChamberReviews = () =>{

    const [isMore] = useState(false)
    // const handleClick = () => {
    //     return setMore(!isMore)
    // }
        
    const reviewsChamb = [
        {
            name:"Eric Olivas",
            date:"7 months ago",
            text:"We called him one hot summer day he was there in no time. Our home was cool and kids were happy. The owner is good at what he does and puts your needs first. ",
            stars:5
        },

    ]

    return(
        <section className="w-full py-20">
            {/* <h2 className="text-center">Our Reviews</h2> */}
                <a href="https://zaubee.com/biz/polar-breeze-air-conditioning-715vk2ql"
                target="_blank"
                rel="noreferrer"
                className="flex w-[300px] mx-auto justify-center">
                    <img
                    alt="logochamber"
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fzaubee.png?alt=media&token=9268d7ad-6a10-435b-bcc5-b2efc215556f"
                   />
                </a> 
                

                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                    <img
                                    src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                    alt="userphotodeatult"
                                    className="w-[50px] shadow-md rounded-[50%]"/>
                                 <div className="ml-3">
                                    <span className="font-medium">{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                    </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex flex-wrap justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                {/* <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button> */}
        </section> 
    );

}

export default ChamberReviews